import { SiteType } from "@flexidao/dto";

export const formatSiteType = (siteType: SiteType): string => {
    switch (siteType) {
        case SiteType.Consumer:
            return "Consumer";
        case SiteType.Producer:
            return "Producer";
    }
};
