/* eslint no-magic-numbers: 0 */

import { ColumnAlign, labelToDataId, mantineTheme } from "@flexidao/ui-lib";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { HTMLPropsRef, MRT_TableOptions } from "mantine-react-table";
import { DEFAULT_CELL_MIN_WIDTH } from "./columns/consts";
import { RowData } from "./types";
import { ReactElement } from "react";
import { RowSelectionState } from "./row-selection/types";
import { BoxProps, Text } from "@mantine/core";

type GetTableStylesProps = {
    dataIdPrefix: string;
    enableExpanding: boolean;
    enableRowSelection: boolean;
    actionsColumnTitle: string;
    rowSelectionState?: RowSelectionState;
    rowCount: number;
    numSelectedRows?: number;
};

export const getTableStyles = <T extends RowData>({
    dataIdPrefix,
    enableExpanding,
    enableRowSelection,
    actionsColumnTitle,
    rowSelectionState,
    rowCount,
    numSelectedRows,
}: GetTableStylesProps): Partial<MRT_TableOptions<T>> => ({
    mantineTableProps: {
        id: labelToDataId({ prefix: dataIdPrefix, label: "table" }),
        highlightOnHover: false,
        sx: {
            tableLayout: "fixed",
            padding: 0,

            // Table head styles
            "& thead": {
                position: "unset",
                "& > tr": {
                    backgroundColor: "inherit",
                    boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.15)",

                    "& > th": {
                        backgroundColor: "inherit",
                        verticalAlign: "middle",
                        paddingBlock: "2px",
                        paddingInline: "8px",
                        lineHeight: "16px",
                        color: mantineTheme.colors?.flexidaoGrey![9],
                        height: "32px", // height for th elements works as min-height
                        border: "none",

                        "&:first-of-type": {
                            paddingLeft: "16px",

                            ...((enableExpanding || enableRowSelection) && {
                                width: "48px !important",
                                minWidth: "48px !important",
                                maxWidth: "48px !important",
                            }),
                        },
                        "&:last-of-type": {
                            paddingRight: "16px",
                        },

                        "& .mantine-InputWrapper-root": {
                            borderBottom: "none !important" as "none",
                        },
                    },

                    '&:has(> th div[aria-hidden="false"])': {
                        "& > th": {
                            alignContent: "baseline",
                            paddingTop: "6px",
                        },
                    },
                },
            },
            "& .mantine-TableHeadCell-Content": {
                position: "unset",
            },
            "& .mantine-TableHeadCell-Content-Wrapper": {
                whiteSpace: "normal",
                overflow: "inherit",
                display: "inline-block",
                wordBreak: "break-word",
            },

            // Table body styles
            "& tbody": {
                position: "unset",

                "& > tr": {
                    paddingInline: "8px",
                    backgroundColor: "inherit",

                    "& > td": {
                        backgroundColor: "inherit",
                        paddingBlock: "4px",
                        paddingInline: "8px",
                        lineHeight: "16px",
                        height: "32px", // height for td elements works as min-height
                        border: "none !important" as "none",

                        "&:first-of-type": {
                            paddingLeft: "16px",

                            // Adjust the width of the first column to accommodate the expand button
                            ...(enableExpanding && {
                                width: "48px !important",
                                minWidth: "48px !important",
                                maxWidth: "48px !important",
                            }),
                        },
                        "&:last-of-type": {
                            paddingRight: "16px",
                        },

                        "& > div": {
                            margin: "auto",
                        },

                        // Hide the expand button when there are no sub-rows
                        "& button.expand-button[disabled]": {
                            display: "none !important" as "none",
                        },
                    },

                    "&:nth-of-type(even) > td": {
                        backgroundColor: mantineTheme.colors?.flexidaoGrey![0],
                    },

                    // Styles for the rows that have sub-rows expanded
                    "&:has(+ tr > td .sub-row)": {
                        "& button.expand-button:hover": {
                            backgroundColor: mantineTheme.colors?.flexidaoGreen![3],
                            color: mantineTheme.colors?.flexidaoGreen![6],
                        },

                        "& > td": {
                            backgroundColor: mantineTheme.colors?.flexidaoGreen![2],
                        },
                    },

                    // Styles for the sub-rows
                    "&:has(> td .sub-row)": {
                        // Add padding to the second column of the sub-row and making it semi-bold,
                        // since the first one is the expand button
                        "& > td:nth-of-type(2)": {
                            paddingLeft: "24px",
                            fontWeight: 600,
                        },

                        "&:nth-of-type(odd) > td": {
                            backgroundColor: mantineTheme.colors?.flexidaoGreen![0],
                        },
                        "&:nth-of-type(even) > td": {
                            backgroundColor: mantineTheme.colors?.flexidaoGreen![1],
                        },
                    },
                },
            },

            // Table footer styles
            "tfoot > tr > th": {
                paddingBlock: "4px",
                paddingInline: "8px",
                lineHeight: "16px",
                border: "none !important" as "none",
                backgroundColor: mantineTheme.colors?.flexidaoGrey![1],
                "&:first-of-type": {
                    paddingLeft: "16px",

                    // Adjust the width of the first column to accommodate the expand button
                    ...(enableExpanding && {
                        width: "48px !important",
                        minWidth: "48px !important",
                        maxWidth: "48px !important",
                    }),
                },
                "&:last-of-type": {
                    paddingRight: "16px",
                },
            },
        },
    },

    displayColumnDefOptions: {
        "mrt-row-actions": {
            header: actionsColumnTitle,
            mantineTableHeadCellProps: {
                id: labelToDataId({
                    prefix: "actions",
                    label: "table-header",
                }),
                sx: {
                    textAlign: "end !important" as ColumnAlign,
                    "> div": {
                        justifyContent: "end !important" as ColumnAlign,
                    },
                },
            },
            mantineTableBodyCellProps: {
                sx: {
                    textAlign: "end !important" as ColumnAlign,
                },
            },
        },
        "mrt-row-expand": {
            size: 48,
        },
    },

    mantineTopToolbarProps: {
        id: labelToDataId({ prefix: dataIdPrefix, label: "top-toolbar" }),
        className: "top-toolbar",
        bg: mantineTheme.colors?.flexidaoGrey![0],
        p: "16px",
        sx: {
            minHeight: "unset",
            padding: "0 !important" as "0",
            position: "unset",

            "& > div": {
                position: "relative",
            },

            "& button:has(svg)": {
                color: mantineTheme.colors?.flexidaoGrey![9],
            },

            "& div:has(> button)": {
                zIndex: 0,
            },
        },
    },

    mantineBottomToolbarProps: {
        id: labelToDataId({
            prefix: dataIdPrefix,
            label: "bottom-toolbar",
        }),
        className: "bottom-toolbar",
        sx: {
            justifyContent: "end !important" as "end",
        },
    },

    mantinePaginationProps: {
        id: labelToDataId({
            prefix: dataIdPrefix,
            label: "pagination",
        }),
    },

    mantineSelectAllCheckboxProps: {
        id: labelToDataId({
            prefix: dataIdPrefix,
            label: "select-all-checkbox",
        }),
    },

    mantinePaperProps: {
        className: "paper",
        sx: {
            padding: "0 !important" as "0",
            boxShadow: "none",
            position: "unset",
            border: 0,
            borderRadius: 0,
            "& > div:has(> table)": {
                position: "unset",
            },
        },
        withBorder: false,
    },

    mantineFilterTextInputProps: {
        className: "filter-text-input",
        placeholder: "Filter by",
        variant: "default",
        size: "xs",
        miw: "unset",
        maw: "unset",
        my: "2px",
    },

    mantineFilterSelectProps: {
        className: "filter-select",
        placeholder: "Select",
        variant: "default",
        size: "xs",
        miw: "unset",
        maw: "unset",
        my: "2px",
        searchable: true,
    },

    mantineFilterMultiSelectProps: {
        className: "filter-multi-select",
        placeholder: "Select",
        variant: "default",
        size: "xs",
        miw: "unset",
        maw: "unset",
        my: "2px",
        searchable: true,
    },

    positionGlobalFilter: "left",
    mantineSearchTextInputProps: {
        className: "table-global-search",
        id: labelToDataId({ prefix: dataIdPrefix, label: "table-global-search" }),
        sx: {
            minWidth: "400px",
            width: "100%",
        },
        variant: "default",
    },

    mantineExpandAllButtonProps: {
        id: labelToDataId({ prefix: dataIdPrefix, label: "expand-all-button" }),
        className: "expand-all-button",
    },

    mantineExpandButtonProps: {
        className: "expand-button",
    },

    icons: {
        IconSortAscending: IconChevronUp,
        IconSortDescending: IconChevronDown,
        IconArrowsSort: IconChevronDown,
    },

    defaultColumn: {
        minSize: DEFAULT_CELL_MIN_WIDTH,
        size: DEFAULT_CELL_MIN_WIDTH,
        mantineTableBodyCellProps: {
            sx: {
                color: mantineTheme.colors?.flexidaoGrey![9],
            },
        },
    },

    // Row selection
    renderTopToolbarCustomActions:
        numSelectedRows != null
            ? (): ReactElement => (
                  <Text size="xs" px={24} sx={{ alignSelf: "center" }}>
                      {numSelectedRows} of {rowCount} row(s) selected
                  </Text>
              )
            : undefined,
    mantineTableBodyRowProps: ({ row: { id } }): HTMLPropsRef<HTMLTableRowElement> & BoxProps => ({
        sx: {
            "& > td": {
                backgroundColor:
                    rowSelectionState && rowSelectionState[id]
                        ? `${mantineTheme.colors?.flexidaoGreen![0]} !important`
                        : "inherit",
            },
        },
    }),
    positionToolbarAlertBanner: "none",
});
