import { Group } from "@mantine/core";
import {
    MRT_ShowHideColumnsButton,
    MRT_TableInstance,
    MRT_ToggleFiltersButton,
} from "mantine-react-table";
import { ReactElement, ReactNode } from "react";
import { RowData } from "../types";
import { mantineTheme } from "@flexidao/ui-lib";

type TopToolbarActionsProps<T extends RowData> = {
    table: MRT_TableInstance<T>;
    topToolbarActionsComponent?: ReactNode;
};

export const TopToolbarActions = <T extends RowData>({
    table,
    topToolbarActionsComponent,
}: TopToolbarActionsProps<T>): ReactElement => (
    <Group spacing="xs">
        {topToolbarActionsComponent}

        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <MRT_ToggleFiltersButton
            table={table}
            sx={{
                color: mantineTheme.colors?.flexidaoGrey![9],
            }}
        />

        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <MRT_ShowHideColumnsButton
            table={table}
            sx={{
                color: mantineTheme.colors?.flexidaoGrey![9],
            }}
        />
    </Group>
);
