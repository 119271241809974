import {
    ReportHubDto,
    reportHubPaths,
    TrackingInstrumentType,
    validateStrictDecoder,
} from "@flexidao/dto";
import { dateDecoder, exhaustiveCheck, uuidDecoder } from "@flexidao/helpers";
import * as D from "schemawax";
import { InferResponse } from "../api-client";
import { useTypeDecoder } from "./eac-data";
import {
    contractTypeDecoder,
    dataAccessDecoder,
    energySourceDecoder,
    globalRegionIdDecoder,
    trackingInstrumentTypeDecoder,
} from "./misc";

export const getEacContractTrackingKpisDecoder: D.Decoder<ReportHubDto.ContractTrackingKpis> =
    D.object({
        required: {
            interval_Wh: D.number,
            intervalPpa_Wh: D.number,
            intervalGreenTariff_Wh: D.number,
            billing_Wh: D.number,
            billingPpa_Wh: D.number,
            billingGreenTariff_Wh: D.number,
            eacsReceived_Wh: D.number,
            eacsAllocated_Wh: D.number,
            eacsPendingAllocation_Wh: D.number,
        },
    });

export const getEacContractTrackingOverviewPpaRowDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewPpaRow> =
    D.object({
        required: {
            contractName: D.string,
            contractId: D.string,
            productionSite: D.string,
            contractType: contractTypeDecoder,
            interval_Wh: D.nullable(D.number),
            billing_Wh: D.nullable(D.number),
            allocatedTrackingInstruments_Wh: D.nullable(D.number),
            intervalVsBillingPercentage: D.nullable(D.number),
            allocatedVsIntervalPercentage: D.nullable(D.number),
            allocatedVsBillingPercentage: D.nullable(D.number),
        },
    });

export const getEacContractTrackingOverviewPpaDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewPpa> =
    D.object({
        required: {
            rowCount: D.number,
            rows: D.array(getEacContractTrackingOverviewPpaRowDecoder),
        },
    });

export const getEacContractTrackingOverviewGreenTariffRowDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewGreenTariffRow> =
    D.object({
        required: {
            contractName: D.string,
            contractId: D.string,
            interval_Wh: D.nullable(D.number),
            billing_Wh: D.nullable(D.number),
            allocatedTrackingInstruments_Wh: D.nullable(D.number),
            intervalVsBillingPercentage: D.nullable(D.number),
            allocatedVsIntervalPercentage: D.nullable(D.number),
            allocatedVsBillingPercentage: D.nullable(D.number),
        },
    });

export const getEacContractTrackingOverviewGreenTariffDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewGreenTariff> =
    D.object({
        required: {
            rows: D.array(getEacContractTrackingOverviewGreenTariffRowDecoder),
        },
    });

export const getEacContractTrackingOverviewUnbundledRowDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewUnbundledRow> =
    D.object({
        required: {
            contractName: D.string,
            contractId: D.string,
            counterpart: D.string,
            deliveryDate: dateDecoder,
            volume_MWh: D.number,
            allocatedTrackingInstruments_Wh: D.nullable(D.number),
            allocatedVsVolumePercentage: D.nullable(D.number),
        },
    });
export const getEacContractTrackingOverviewUnbundledDecoder: D.Decoder<ReportHubDto.ContractTrackingOverviewUnbundled> =
    D.object({
        required: {
            rows: D.array(getEacContractTrackingOverviewUnbundledRowDecoder),
        },
    });

export const region: D.Decoder<ReportHubDto.Region> = D.object({
    required: {
        regionId: D.string,
        name: D.string,
    },
});
export const contractOptionPpa: D.Decoder<ReportHubDto.ContractOptionPpa> = D.object({
    required: {
        contractId: D.string,
        name: D.string,
        productionSitesIds: D.array(D.string),
    },
});
export const contractOption: D.Decoder<ReportHubDto.ContractOption> = D.object({
    required: {
        contractId: D.string,
        name: D.string,
    },
});
export const site: D.Decoder<ReportHubDto.Site> = D.object({
    required: {
        siteId: D.string,
        name: D.string,
    },
});

export const getRegions: D.Decoder<ReportHubDto.GetRegions> = D.array(region);
export const getContractsPpa: D.Decoder<ReportHubDto.GetContractsPpa> = D.array(contractOptionPpa);
export const getContractsGreenTariff: D.Decoder<ReportHubDto.GetContractsGreenTariff> =
    D.array(contractOption);
export const getSites: D.Decoder<ReportHubDto.GetSites> = D.array(site);

export const getContractTrackingMonthlyPpaRowDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyPpaRow> =
    D.object({
        required: {
            month: D.number,
            interval_Wh: D.nullable(D.number),
            billing_Wh: D.nullable(D.number),
            allocatedTrackingInstruments_Wh: D.nullable(D.number),
            intervalVsBillingPercentage: D.nullable(D.number),
            allocatedVsIntervalPercentage: D.nullable(D.number),
            allocatedVsBillingPercentage: D.nullable(D.number),
        },
    });

export const getContractTrackingMonthlyPpaDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyPpa> =
    D.array(getContractTrackingMonthlyPpaRowDecoder);

export const getContractTrackingMonthlyGreenTariffRowDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyGreenTariffRow> =
    D.object({
        required: {
            month: D.number,
            interval_Wh: D.nullable(D.number),
            billing_Wh: D.nullable(D.number),
            intervalVsBillingPercentage: D.nullable(D.number),
        },
    });

export const getContractTrackingMonthlyGreenTariffDecoder: D.Decoder<ReportHubDto.ContractTrackingMonthlyGreenTariff> =
    D.array(getContractTrackingMonthlyGreenTariffRowDecoder);

const eacAllocationDecoder: D.Decoder<ReportHubDto.Allocation> = D.object({
    required: {
        trackingInstrumentType: D.literal(TrackingInstrumentType.EAC),
        eacSchemeId: D.string,
        transactionFromName: D.nullable(D.string),
        productionSiteName: D.string,
        productionSiteId: D.string,
        consumptionSiteId: D.nullable(D.string),
        consumptionPeriodStart: dateDecoder,
        consumptionPeriodEnd: dateDecoder,
        transactionId: D.string,
        uploadDate: dateDecoder,
        allocatedTo: D.nullable(D.string),
        allocatedToName: D.nullable(D.string),
        isUnbundled: D.boolean,
        consumptionOrganizationName: D.nullable(D.string),
        volumeWh: D.number,
        energySourceId: D.nullable(energySourceDecoder),
        productionCountryId: D.nullable(D.string),
        consumptionTimezone: D.string,
    },
});

const attestationAllocationDecoder: D.Decoder<ReportHubDto.Allocation> = D.object({
    required: {
        trackingInstrumentType: D.literal(TrackingInstrumentType.Attestation),
        eacSchemeId: D.nullable(D.string),
        transactionFromName: D.nullable(D.string),
        productionSiteName: D.nullable(D.string),
        productionSiteId: D.nullable(D.string),
        consumptionSiteId: D.nullable(D.string),
        consumptionPeriodStart: dateDecoder,
        consumptionPeriodEnd: dateDecoder,
        transactionId: D.string,
        uploadDate: dateDecoder,
        allocatedTo: D.nullable(D.string),
        allocatedToName: D.nullable(D.string),
        isUnbundled: D.boolean,
        consumptionOrganizationName: D.nullable(D.string),
        volumeWh: D.number,
        energySourceId: D.nullable(energySourceDecoder),
        productionCountryId: D.nullable(D.string),
        consumptionTimezone: D.string,
    },
});

const allocationDecoder: D.Decoder<ReportHubDto.Allocation> = D.oneOf(
    eacAllocationDecoder,
    attestationAllocationDecoder,
);

export const getAllocationsByTenantIdResponseDecoder: D.Decoder<ReportHubDto.GetAllocationsByTenantIdResponse> =
    D.object({
        required: {
            allocations: D.array(allocationDecoder),
            totalAllocations: D.number,
        },
    });

export const allocationPayloadDecoder: D.Decoder<PostAllocationsByTenantIdResponse[number]> =
    D.object({
        required: {
            trackingInstrumentId: D.string,
            trackingInstrumentType: trackingInstrumentTypeDecoder,
            allocationTarget: D.oneOf(uuidDecoder, D.literal("unbundled")),
        },
    });

type PostAllocationsByTenantIdResponse = InferResponse<
    reportHubPaths,
    "/{tenantId}/allocations",
    "post",
    201
>;

export const postAllocationsByTenantIdResponseDecoder: D.Decoder<PostAllocationsByTenantIdResponse> =
    D.array(allocationPayloadDecoder);

// EX-4909 only using receivesEacs for now

// const contractEacCodeDecoder = D.object({
//     required: {
//         eacCodeId: D.number,
//         eacCode: D.string,
//     },
// });
export const baseContractDecoder: D.Decoder<ReportHubDto.BaseContract> = D.object({
    required: {
        contractId: D.string,
        tenantId: D.string,
        receivesEacs: D.boolean,
        // cancellationParty: D.oneOf(D.null, contractEacCodeDecoder),
        // beneficiaries: D.array(contractEacCodeDecoder),
    },
});

export const sourcingMethodDecoder: D.Decoder<ReportHubDto.SourcingMethod> = D.string.andThen(
    (value: string): ReportHubDto.SourcingMethod => {
        const castedValue = value as ReportHubDto.SourcingMethod;

        switch (castedValue) {
            case "default_renewable_electricity_from_grid_95_no_allocations":
            case "default_renewable_electricity_from_grid_with_eacs":
            case "direct_line_ppa":
            case "on_site_ppa":
            case "physical_ppa":
            case "project_specific_contract":
            case "retail_green_electricity":
            case "unbundled_eacs":
            case "virtual_ppa":
            case "unknown":
            case null:
                return castedValue;
            default:
                return exhaustiveCheck(castedValue, `Unknown sourcing method '${castedValue}'`);
        }
    },
);

export const contractReportingSettingsDecoder: D.Decoder<ReportHubDto.ContractReportingSettings> =
    D.object({
        required: {
            contractId: D.string,
            sourcingMethod: sourcingMethodDecoder,
        },
    });

export const eacCodeDecoder: D.Decoder<ReportHubDto.EacCode> = D.object({
    required: {
        tenantId: D.string,
        eacCodeId: D.number,
        eacCode: D.string,
        registryId: D.string,
        useType: useTypeDecoder,
    },
});

/* TODO: Components and GetComponent should be on dtos */
type Components = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schemas: Record<string, any>;
};
export type GetComponent<C extends Components, Name extends keyof C["schemas"]> = C extends {
    schemas: {
        // eslint-disable-next-line no-unused-vars
        [N in Name]: infer Q;
    };
}
    ? Q
    : never;

export const countryDecoder: D.Decoder<ReportHubDto.Country> = D.object({
    required: {
        countryId: D.string,
        name: D.string,
        enabled: D.boolean,
        marketId: D.nullable(D.string),
        passiveProcurement: D.boolean,
    },
});

export const biddingZoneDecoder: D.Decoder<ReportHubDto.BiddingZone> = D.object({
    required: {
        zoneId: D.string,
        countryId: D.string,
        name: D.string,
        timezones: D.array(D.string),
        enabled: D.boolean,
        dataAccess: dataAccessDecoder,
        marketId: D.nullable(D.string),
        regionId: D.string,
    },
});

export const marketDecoder: D.Decoder<ReportHubDto.Market> = D.object({
    required: {
        marketId: D.string,
        name: D.string,
        description: D.string,
    },
});

export const annualEmissionFactorDecoder: D.Decoder<ReportHubDto.AnnualEmissionFactor> = D.object({
    required: {
        biddingZoneId: D.string,
        reportingYear: D.number,
        marketBasedEmissions_g_Wh: D.nullable(D.number),
        marketBasedEmissionsSourceId: D.nullable(D.string),
        marketBasedEmissionsSourceName: D.nullable(D.string),
        marketBasedEmissionsSourceYear: D.nullable(D.number),
        locationBasedEmissions_g_Wh: D.nullable(D.number),
        locationBasedEmissionsSourceId: D.nullable(D.string),
        locationBasedEmissionsSourceName: D.nullable(D.string),
        locationBasedEmissionsSourceYear: D.nullable(D.number),
    },
});

const assignmentDetailDecoder = validateStrictDecoder<ReportHubDto.AssignmentDetail>()(
    D.object({
        required: {
            trackingInstrumentType: D.nullable(trackingInstrumentTypeDecoder),
            eacCancellationId: D.nullable(D.string),
            attestationId: D.nullable(D.string),
            assignedWh: D.number,
            productionPeriodStart: D.nullable(dateDecoder),
            productionPeriodEnd: D.nullable(dateDecoder),
            productionTimezone: D.nullable(D.string),
            eacSchemeId: D.nullable(D.string),
            productionCountryId: D.nullable(D.string),
            productionCountryName: D.nullable(D.string),
            energySourceId: D.nullable(energySourceDecoder),
            productionSiteCommissioningDate: D.nullable(dateDecoder),
            sourcingMethod: D.nullable(sourcingMethodDecoder),
            contractId: D.nullable(D.string),
            contractStartTimeLocal: D.nullable(dateDecoder),
            documentId: D.nullable(D.number),
        },
    }),
);

export const countryAssignmentResponseDecoder =
    validateStrictDecoder<ReportHubDto.CountryAssignmentsResponse>()(
        D.object({
            required: {
                totalCount: D.number,
                assignments: D.array(assignmentDetailDecoder),
            },
        }),
    );

export const postAssignmentResponse = D.string;
export const countryAssignmentKpiDecoder: D.Decoder<ReportHubDto.CountryAssignmentKpis> = D.object({
    required: {
        countryId: D.string,
        countryName: D.string,
        consumptionWh: D.number,
        globalRegionId: globalRegionIdDecoder,
        assignedWh: D.number,
        locationBasedEmissionsT: D.number,
        marketBasedEmissionsT: D.number,
        passiveProcurement: D.boolean,
        biddingZoneIds: D.array(D.string),
    },
});

export const reportingKpisDecoder: D.Decoder<ReportHubDto.GetReportingKpisResponse> = D.object({
    required: {
        totals: D.object({
            required: {
                consumptionWh: D.number,
                assignedWh: D.number,
                locationBasedEmissionsT: D.number,
                marketBasedEmissionsT: D.number,
            },
        }),
        byCountry: D.array(countryAssignmentKpiDecoder),
    },
});

export const contractOptionDecoder: D.Decoder<ReportHubDto.ContractOption> = D.object({
    required: {
        contractId: D.string,
        name: D.string,
    },
});

export const contractOptionsArrayDecoder: D.Decoder<Array<ReportHubDto.ContractOption>> =
    D.array(contractOptionDecoder);

export const allocationFiltersDecoder: D.Decoder<ReportHubDto.AllocationFilters> = D.object({
    required: {
        transactionIds: D.array(D.string),
        productionSiteNames: D.array(D.nullable(D.string)),
        allocatedToNames: D.array(D.nullable(D.string)),
        productionCountries: D.array(D.nullable(D.string)),
    },
});

export const getGlobalOverviewKpisDecoder: D.Decoder<ReportHubDto.GlobalOverviewKpis> = D.object({
    required: {
        consumption: D.object({
            required: {
                totalWh: D.number,
                trackingInstrumentCoverage: D.number,
            },
        }),
        volumes: D.object({
            required: {
                totalWh: D.number,
                ppaWh: D.number,
                greenTariffWh: D.number,
                unbundledWh: D.number,
            },
        }),
        trackingInstruments: D.object({
            required: {
                totalWh: D.number,
                eacCancelledWh: D.number,
                eacTotalWh: D.number,
                attestationTotalWh: D.number,
            },
        }),
    },
});

const getGlobalOverviewCountryCoverageDetailDecoder: D.Decoder<ReportHubDto.GlobalOverviewCountryCoverageDetail> =
    D.object({
        required: {
            tenantId: D.string,
            countryId: D.string,
            countryName: D.string,
            consumptionWh: D.number,
            purchasedVolumeWh: D.number,
            trackingInstrumentWh: D.number,
            coveredShare: D.number,
            uncoveredWh: D.number,
        },
    });

export const getGlobalOverviewCountriesCoverageDecoder: D.Decoder<ReportHubDto.GlobalOverviewCountriesCoverage> =
    D.object({
        required: {
            countriesCoverage: D.array(getGlobalOverviewCountryCoverageDetailDecoder),
        },
    });

export const getCountriesDecoder: D.Decoder<ReportHubDto.GetCountries> = D.array(countryDecoder);

const reportingPeriodDecoder: D.Decoder<ReportHubDto.ReportingPeriod> = D.object({
    required: {
        tenantId: D.string,
        year: D.number,
        startReportingPeriodLocal: dateDecoder,
        endReportingPeriodLocal: dateDecoder,
    },
});
export const getReportingPeriodsDecoder: D.Decoder<ReportHubDto.GetReportingPeriods> =
    D.array(reportingPeriodDecoder);

export const getContractTrackingKpisPpaDecoder: D.Decoder<ReportHubDto.ContractTrackingKpisPpa> =
    D.object({
        required: {
            interval_Wh: D.number,
            billing_Wh: D.number,
            allocatedTrackingInstruments_Wh: D.number,
        },
    });
export const getContractTrackingKpisGreenTariffDecoder: D.Decoder<ReportHubDto.ContractTrackingKpisGreenTariff> =
    D.object({
        required: {
            interval_Wh: D.number,
            billing_Wh: D.number,
            allocatedTrackingInstruments_Wh: D.number,
        },
    });

export const getContractTrackingKpisUnbundledDecoder: D.Decoder<ReportHubDto.ContractTrackingKpisUnbundled> =
    D.object({
        required: {
            volume_MWh: D.number,
            allocatedTrackingInstruments_Wh: D.number,
        },
    });
