import { ReactElement } from "react";
import { Group, Text } from "@mantine/core";
import { formatDateHourMinute } from "@flexidao/ui-lib";
import { EmptyCell } from "./empty-cell";
import { getClassNameForSubRow } from "./utils";

type DateCellProps = {
    date: Date | null;
    timezone?: string | null;
    timezoneLabel?: string | null;
    isSubRow?: boolean;
};

export const DatetimeCell = ({
    date,
    timezone,
    timezoneLabel,
    isSubRow = false,
}: DateCellProps): ReactElement => {
    if (date == null) {
        return <EmptyCell isSubRow={isSubRow} />;
    }

    const formattedDatetime: string = formatDateHourMinute(date, timezone ?? undefined);

    const splitDatetime: Array<string> = formattedDatetime.split(",");
    if (splitDatetime[0] == null || splitDatetime[1] == null) {
        return <EmptyCell isSubRow={isSubRow} />;
    }

    const formattedDate: string = splitDatetime[0];
    const formattedTime: string = splitDatetime[1];

    return (
        <Group
            noWrap
            align="baseline"
            spacing={4}
            sx={{
                justifyContent: "flex-end",
            }}
            className={getClassNameForSubRow(isSubRow)}
        >
            <Text size={14}>{formattedDate}</Text>
            <Text size={11}>{formattedTime}</Text>
            {timezoneLabel && (
                <Text size={11} color="flexidaoGrey.8">
                    {timezoneLabel}
                </Text>
            )}
        </Group>
    );
};
