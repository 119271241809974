import { Magnitude, TooltipType } from "@flexidao/ui-lib";
import { MRT_Cell, MRT_Column, MRT_Header, MRT_Row, MRT_TableInstance } from "mantine-react-table";
import { ReactNode, RefObject } from "react";
import { RowData } from "../types";

export enum FilterType {
    Text = "text",
    Select = "select",
    MultiSelect = "multi-select",
}

export enum ColumnAlign {
    Start = "start",
    End = "end",
    Center = "center",
}

export enum ColumnType {
    Number = "number",
    Division = "division",
    String = "string",
    Date = "date",
    Datetime = "datetime",
    Boolean = "boolean",
    Custom = "custom",
}

export type ColumnFilterOption = {
    value: string;
    label: string;
};

export type ColumnFilterProps =
    | {
          filterType: FilterType.Text;
      }
    | {
          filterType: FilterType.Select;
          options: Array<ColumnFilterOption>;
      }
    | {
          filterType: FilterType.MultiSelect;
          options: Array<ColumnFilterOption>;
      };

export type DivisionColumnType<
    T extends number | null = number | null,
    K extends number | null = number | null,
> = {
    value: T;
    total: K;
    units?: string;
    color?: string;
    numDecimals?: number;
};

export type NumberColumnType<T extends number | null = number | null> = {
    value: T;
    numDecimals?: number;
    weight?: "normal" | "semibold" | "bold";
    color?: string;
    units?: string;
    displaySpaceBetweenNumberAndUnits?: boolean;
    physicalRound?: {
        enablePhysicalRound: true;
        minMagnitude?: Magnitude;
        maxMagnitude?: Magnitude;
    };
};

export type DatetimeColumnType<T extends Date | null = Date | null> = {
    date: T;
    timezone?: string | null;
    timezoneLabel?: string | null;
};
export type DateColumnType<T extends Date | null = Date | null> = {
    date: T;
    timezone?: string | null;
};
export type StringColumnType<T extends string | null = string | null> = T;
export type BooleanColumnType<T extends boolean | null = boolean | null> = T;
export type CustomColumnType<T extends RowData = RowData> =
    | ReactNode
    | ((props: {
          cell: MRT_Cell<T>;
          renderedCellValue: number | string | ReactNode;
          column: MRT_Column<T>;
          row: MRT_Row<T>;
          rowRef?: RefObject<HTMLTableRowElement>;
          table: MRT_TableInstance<T>;
      }) => ReactNode);
export type RowExtraDataColumnType<T> = T;

export type Column<T extends RowData> = {
    columnType: ColumnType;
    accessorKey: Extract<keyof T, string>;
    header: string;
    textAlign?: ColumnAlign;
    headerTooltip?: TooltipType;
    enableSorting?: boolean;
    filterProps?: ColumnFilterProps;
    visible?: boolean;
    size?: number;
    minSize?: number;
    maxSize?: number;
    grow?: boolean;
    enableClickToCopy?: boolean;
    Cell?:
        | ReactNode
        | ((props: {
              cell: MRT_Cell<T>;
              renderedCellValue: number | string | ReactNode;
              column: MRT_Column<T>;
              row: MRT_Row<T>;
              rowRef?: RefObject<HTMLTableRowElement>;
              table: MRT_TableInstance<T>;
          }) => ReactNode);
    Footer?:
        | ReactNode
        | ((props: {
              column: MRT_Column<T>;
              footer: MRT_Header<T>;
              table: MRT_TableInstance<T>;
          }) => ReactNode);
};
