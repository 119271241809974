import { ReactElement } from "react";
import { Group, Stack, Text } from "@mantine/core";
import {
    ENERGY_NUM_DECIMALS,
    formatNumber,
    HistogramCurveSeries,
    labelToDataId,
} from "@flexidao/ui-lib";
import { TooltipContainer } from "../utils";
import { getMillisecondsFromGranularity } from "./utils";
import { DataGranularity } from "@flexidao/dto";

type HistogramTooltipProps = {
    unit: string;
    tooltipLeft: number | undefined;
    tooltipTop: number | undefined;
    tooltipData?: Array<HistogramCurveSeries>;
    granularity: DataGranularity;
    tooltipDateFormatter: (date: Date) => string;
};

export const Tooltip = ({
    tooltipLeft,
    tooltipTop,
    tooltipData,
    granularity,
    tooltipDateFormatter,
    unit,
}: HistogramTooltipProps): ReactElement => {
    const showTooltip = tooltipTop && tooltipLeft;

    const startDateFromData: Date | null = tooltipData?.[0]?.seriesData[0]?.date ?? null;

    const periodMs: number = getMillisecondsFromGranularity(granularity);

    const startDate: Date | null = startDateFromData;
    const endDate: Date | null = startDateFromData
        ? new Date(startDateFromData.getTime() + periodMs)
        : null;

    return showTooltip ? (
        <TooltipContainer tooltipTop={tooltipTop} tooltipLeft={tooltipLeft}>
            <Stack spacing="xs" data-id="histogram-tooltip--container">
                <Stack spacing={0}>
                    <Group
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Text weight="bold" size="sm" c="flexidaoGrey.5">
                            From:
                        </Text>

                        <Text weight="bold" size="sm" data-id="histogram-tooltip--start-date">
                            {startDate ? tooltipDateFormatter(startDate) : "-"}
                        </Text>
                    </Group>

                    <Group
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Text weight="bold" size="sm" c="flexidaoGrey.5">
                            To:
                        </Text>

                        <Text weight="bold" size="sm" data-id="histogram-tooltip--end-date">
                            {endDate ? tooltipDateFormatter(endDate) : "-"}
                        </Text>
                    </Group>
                </Stack>

                {tooltipData?.map((series: HistogramCurveSeries) => (
                    <Group
                        key={series.seriesLabel}
                        data-id={labelToDataId({
                            prefix: "tooltip-item",
                            label: series.seriesLabel,
                        })}
                        spacing="16px"
                        noWrap
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Group spacing={4}>
                            <svg width={12} height={12}>
                                <circle fill={series.seriesColor} r={6} cx={6} cy={6} />
                            </svg>

                            <Text size="sm">{series.seriesLabel}:</Text>
                        </Group>

                        <Group spacing={4} align="baseline">
                            <Text size="sm" fw="bold">
                                {formatNumber(
                                    series.seriesData[0]?.value ?? null,
                                    ENERGY_NUM_DECIMALS,
                                )}
                            </Text>

                            {series.seriesData[0]?.value != null && (
                                <Text size="10px" fw="bold">
                                    {unit}
                                </Text>
                            )}
                        </Group>
                    </Group>
                ))}
            </Stack>
        </TooltipContainer>
    ) : (
        <></>
    );
};
