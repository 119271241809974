import { ExpectedIntervalReadingType } from "@flexidao/dto";

export const formatExpectedIntervalReadingType = (
    expectedIntervalReadingType: ExpectedIntervalReadingType,
): string => {
    switch (expectedIntervalReadingType) {
        case ExpectedIntervalReadingType.IntervalConsumption:
            return "Consumption";
        case ExpectedIntervalReadingType.IntervalProduction:
            return "Production";
        case ExpectedIntervalReadingType.IntervalProductionAndConsumption:
            return "Production and consumption";
        case ExpectedIntervalReadingType.NetMetering:
            return "Net metering";
        case ExpectedIntervalReadingType.None:
            return "Billing";
    }
};
