import { ExpectedReadingGranularity } from "@flexidao/dto";

export const formatExpectedReadingGranularity = (
    expectedReadingGranularity: ExpectedReadingGranularity,
): string => {
    switch (expectedReadingGranularity) {
        case ExpectedReadingGranularity.QuarterHourly:
            return "15 minutes";
        case ExpectedReadingGranularity.HalfHourly:
            return "30 minutes";
        case ExpectedReadingGranularity.Hourly:
            return "1 hour";
        case ExpectedReadingGranularity.Monthly:
            return "1 month";
        case ExpectedReadingGranularity.AnyGranularity:
            return "Any granularity";
    }
};
