import { formatNumber } from "./formatters/format-number";

export const formatIntInput = {
    formatter: (value: string | undefined): string => {
        if (!value) {
            return "";
        }
        return formatNumber(parseInt(value.replace(/[^0-9]/g, "")));
    },
    parser: (value: string | undefined): string => {
        if (!value) {
            return "";
        }
        return value.replace(/[^0-9]/g, "");
    },
};
