import { DataGranularity } from "@flexidao/dto";
import {
    HistogramCurveSeries,
    NullableDateValue,
    formatNumber,
    getTooltipDateFormatter,
    getXTickFormatter,
    labelToDataId,
} from "@flexidao/ui-lib";
import { flexidaoGrey } from "@flexidao/ui-lib/mantine-theme/colours/flexidao-colors";
import { Stack } from "@mantine/core";
import { AxisBottom, AxisRight } from "@visx/axis";
import { curveStepAfter } from "@visx/curve";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear, scaleOrdinal, scaleTime } from "@visx/scale";
import { Bar, Line, LinePath } from "@visx/shape";
import { useTooltip } from "@visx/tooltip";
import { max } from "@visx/vendor/d3-array";
import { NumberValue } from "d3-scale";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { HistogramLegend } from "./legend";
import { Tooltip } from "./tooltip";
import {
    getAllDatesFromPeriod,
    getSeriesDataForLineChart,
    getTruncatedDate,
    isPointDefined,
} from "./utils";

// Data accessors
const getX = (d: NullableDateValue): Date => d.date;
const getY = (d: NullableDateValue): number | null => d.value;

// Margins and paddings
const axisRightWidth = 100;
const axisBottomHeight = 21;

const margin = { bottom: axisBottomHeight, left: 0, right: 0, top: 0 };
const padding = { bottom: 0, left: 0, right: axisRightWidth, top: 20 };

// Types
type HistogramProps = {
    chartDataId: string;
    chartHeight: number;
    unit?: string;
    allSeries: Array<HistogramCurveSeries>;
    period: [Date, Date];
    granularity?: DataGranularity;
};

export const Histogram = ({
    chartDataId,
    chartHeight,
    allSeries,
    period: [startTime, endTime],
    unit = "",
    granularity = DataGranularity.Hourly,
}: HistogramProps): ReactElement => {
    const xTickFormatter = useMemo(
        () => getXTickFormatter(startTime, endTime, granularity),
        [startTime, endTime, granularity],
    );
    const tooltipDateFormatter = useMemo(() => getTooltipDateFormatter(granularity), [granularity]);

    // ----- ----- CHART BODY VARIABLES START ----- -----
    // We get a local copy of the series to be able to toggle them
    const [localSeries, setLocalSeries] = useState<Array<HistogramCurveSeries>>(allSeries);

    // We get the data only from the active series
    // because we need to calculate the scales with only the data that will be displayed (active series)
    const allDataFromActiveSeries: Array<NullableDateValue> = localSeries
        .filter((s: HistogramCurveSeries): boolean => s.isSeriesActive)
        .flatMap((s: HistogramCurveSeries) => s.seriesData);

    // Ranges
    const xDomain: [Date, Date] = useMemo(() => [startTime, endTime], [startTime, endTime]);
    const yDomain: [number, number] = useMemo(
        () => [0, max(allDataFromActiveSeries, getY) as number],
        [allDataFromActiveSeries],
    );

    // Scales (calculated with all data from active series and the period given)
    const allDatesFromPeriod: Array<Date> = useMemo(
        () => getAllDatesFromPeriod([startTime, endTime], granularity),
        [startTime, endTime, granularity],
    );

    const lineXScale = useMemo(
        () =>
            scaleTime<number>({
                domain: xDomain,
            }),
        [xDomain],
    );
    const lineYScale = useMemo(
        () =>
            scaleLinear<number>({
                domain: yDomain,
            }),
        [yDomain],
    );
    const barXScale = useMemo(
        () =>
            scaleBand<Date>({
                domain: allDatesFromPeriod,
                padding: 0,
            }),
        [allDatesFromPeriod],
    );

    // ----- ----- CHART BODY VARIABLES END ----- -----

    // ----- ----- CHART LEGEND VARIABLES START ----- -----
    // All series labels and colors
    const allSeriesLabelList: Array<string> = localSeries.map(
        (s: HistogramCurveSeries) => s.seriesLabel,
    );
    const allSeriesColorList: Array<string> = localSeries.map(
        (s: HistogramCurveSeries) => s.seriesColor,
    );
    const colorScale = scaleOrdinal<string, string>({
        domain: allSeriesLabelList,
        range: allSeriesColorList,
    });
    const activeSeriesDict: Record<string, boolean> = localSeries.reduce(
        (acc: Record<string, boolean>, s: HistogramCurveSeries) => {
            acc[s.seriesLabel] = s.isSeriesActive;
            return acc;
        },
        {},
    );
    const toggleActiveSeries = (label: string): void => {
        setLocalSeries((prevState: Array<HistogramCurveSeries>) =>
            prevState.map((series) =>
                series.seriesLabel === label
                    ? { ...series, isSeriesActive: !series.isSeriesActive }
                    : series,
            ),
        );
    };
    // ----- ----- CHART LEGEND VARIABLES END ----- -----

    // ----- ----- TOOLTIP VARIABLES START ----- -----
    const numActiveSeries: number = localSeries.filter(
        (s: HistogramCurveSeries) => s.isSeriesActive,
    ).length;
    const shouldShowToolip: boolean = numActiveSeries > 0;
    const { tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
        useTooltip<Array<HistogramCurveSeries>>();

    const handleTooltip = useCallback(
        (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
            const { x, y } = localPoint(event) || { x: 0, y: 0 };

            const hoveredDate: Date = lineXScale.invert(x);
            const truncatedDate: Date = getTruncatedDate(hoveredDate, granularity);

            // Get consumption and production for hovered date
            const activeSeries: Array<HistogramCurveSeries> | undefined = localSeries.filter(
                (s: HistogramCurveSeries) => s.isSeriesActive,
            );
            const tooltipData: Array<HistogramCurveSeries> = activeSeries.map(
                (series: HistogramCurveSeries) => {
                    const seriesData: Array<NullableDateValue> = series.seriesData.filter(
                        (d: NullableDateValue): boolean =>
                            d.date.getTime() === truncatedDate.getTime(),
                    );
                    return {
                        ...series,
                        seriesData,
                    };
                },
            );

            if (tooltipData) {
                showTooltip({
                    tooltipData,
                    tooltipLeft: x,
                    tooltipTop: y,
                });
            } else {
                hideTooltip();
            }
        },
        [hideTooltip, lineXScale, localSeries, showTooltip],
    );
    // ----- ----- TOOLTIP VARIABLES END ----- -----

    return (
        <Stack data-id={chartDataId} spacing="xs" pos="relative">
            {/* ----- Chart body start ----- */}
            <ParentSize style={{ width: "100%", height: chartHeight }}>
                {(parent): ReactElement => {
                    // Update bounds and scales
                    const chartWidth: number = parent.width - margin.left - margin.right;

                    if (chartWidth <= 0) {
                        return <></>;
                    }

                    lineXScale.range([padding.left, chartWidth - padding.right]);
                    barXScale.range([padding.left, chartWidth - padding.right]);
                    lineYScale.range([
                        chartHeight - margin.top - margin.bottom - padding.bottom,
                        padding.top,
                    ]);

                    // Data accessors
                    let lastNonNullValue: number = 0;
                    const x = (d: NullableDateValue): number => lineXScale(getX(d)) ?? 0;
                    const y = (d: NullableDateValue): number => {
                        const value: number | null = getY(d);

                        if (value != null) {
                            lastNonNullValue = value;
                        }

                        return lineYScale(lastNonNullValue);
                    };

                    // Formatters
                    const tickFormat = (x: NumberValue): string =>
                        formatNumber(x.valueOf(), 0, unit);

                    return (
                        <>
                            <svg width={chartWidth} height={chartHeight}>
                                <Group top={margin.top} left={margin.left}>
                                    {/* ----- Series start ----- */}
                                    {localSeries.map(
                                        ({
                                            isSeriesActive,
                                            seriesLabel,
                                            seriesColor,
                                            seriesData,
                                        }: HistogramCurveSeries) => {
                                            if (!isSeriesActive) {
                                                return null;
                                            }

                                            const seriesDataId: string = labelToDataId({
                                                prefix: `${chartDataId}-series`,
                                                label: seriesLabel,
                                            });

                                            const seriesDataForLineChart: Array<NullableDateValue> =
                                                getSeriesDataForLineChart(seriesData, granularity);

                                            // TODO: the line path has a problem, which is that, if we are missing just one value, it will still draw the line
                                            //  It works as expected if we are missing more than one value
                                            return (
                                                <Group key={seriesDataId} data-id={seriesDataId}>
                                                    {/* Line chart */}
                                                    <LinePath<NullableDateValue>
                                                        data-id={`${seriesDataId}-line`}
                                                        curve={curveStepAfter}
                                                        data={seriesDataForLineChart}
                                                        x={x}
                                                        y={y}
                                                        defined={(
                                                            dateValue: NullableDateValue,
                                                            i: number,
                                                        ): boolean => {
                                                            const previousPoint: NullableDateValue | null =
                                                                i === 0
                                                                    ? null
                                                                    : seriesDataForLineChart[
                                                                          i - 1
                                                                      ] ?? null;

                                                            return isPointDefined({
                                                                currentPoint: dateValue,
                                                                previousPoint: previousPoint,
                                                            });
                                                        }}
                                                        stroke={seriesColor}
                                                        strokeWidth={1}
                                                        strokeOpacity={1}
                                                        shapeRendering="geometricPrecision"
                                                    />

                                                    {/* Bar chart */}
                                                    {seriesData.map(
                                                        (dateValue: NullableDateValue) => {
                                                            // Data id for the bar
                                                            const dataId: string = `${seriesDataId}-bar-${dateValue.date.toISOString()}`;

                                                            // Bar dimensions
                                                            const barWidth: number =
                                                                barXScale.bandwidth();
                                                            const barHeight: number =
                                                                chartHeight -
                                                                margin.top -
                                                                margin.bottom -
                                                                padding.bottom -
                                                                (lineYScale(dateValue.value ?? 0) ??
                                                                    0);

                                                            const barX: number | undefined =
                                                                barXScale(dateValue.date);
                                                            const barY: number =
                                                                chartHeight -
                                                                margin.top -
                                                                margin.bottom -
                                                                padding.bottom -
                                                                barHeight;

                                                            return (
                                                                <Bar
                                                                    data-id={dataId}
                                                                    key={dataId}
                                                                    x={barX}
                                                                    y={barY}
                                                                    width={barWidth}
                                                                    height={barHeight}
                                                                    fill={seriesColor}
                                                                    opacity={0.1}
                                                                />
                                                            );
                                                        },
                                                    )}
                                                </Group>
                                            );
                                        },
                                    )}
                                    {/* ----- Series end ----- */}

                                    {/* ----- Tooltip start ----- */}
                                    {shouldShowToolip && (
                                        <>
                                            <Bar
                                                width={
                                                    chartWidth -
                                                    margin.left -
                                                    margin.right -
                                                    padding.right
                                                }
                                                height={chartHeight - margin.top - margin.bottom}
                                                fill="transparent"
                                                onMouseMove={handleTooltip}
                                                onMouseLeave={hideTooltip}
                                            />

                                            <Line
                                                from={{
                                                    x: tooltipLeft
                                                        ? tooltipLeft - margin.left - padding.left
                                                        : 0,
                                                    y: 0,
                                                }}
                                                to={{
                                                    x: tooltipLeft ? tooltipLeft - margin.left : 0,
                                                    y: chartHeight - margin.top - margin.bottom,
                                                }}
                                                stroke={flexidaoGrey[3]}
                                                strokeWidth={1}
                                                pointerEvents="none"
                                            />
                                        </>
                                    )}
                                    {/* ----- Tooltip end ----- */}
                                </Group>

                                {/* ----- Axes start ----- */}
                                <AxisRight
                                    hideZero
                                    numTicks={4}
                                    tickFormat={tickFormat}
                                    scale={lineYScale}
                                    left={chartWidth - padding.right}
                                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                                    tickLabelProps={() => ({
                                        fontSize: 10,
                                        fontWeight: 400,
                                        fill: flexidaoGrey[7],
                                        textAnchor: "start",
                                        x: "12",
                                        dy: "3px",
                                    })}
                                    tickLineProps={{
                                        stroke: flexidaoGrey[3],
                                    }}
                                    tickLength={4}
                                    stroke={flexidaoGrey[3]}
                                />
                                <AxisBottom
                                    numTicks={4}
                                    tickFormat={xTickFormatter}
                                    scale={lineXScale}
                                    top={chartHeight + margin.top - margin.bottom - padding.bottom}
                                    left={margin.left}
                                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                                    tickLabelProps={() => ({
                                        fontSize: 10,
                                        fontWeight: 400,
                                        fill: flexidaoGrey[7],
                                        textAnchor: "middle",
                                        y: "18",
                                    })}
                                    tickLineProps={{
                                        stroke: flexidaoGrey[3],
                                    }}
                                    tickLength={4}
                                    stroke={flexidaoGrey[3]}
                                />
                                {/* ----- Axes end ----- */}
                            </svg>

                            {/* ----- Tooltip start ----- */}
                            {shouldShowToolip && (
                                <Tooltip
                                    unit={unit}
                                    granularity={granularity}
                                    tooltipLeft={tooltipLeft}
                                    tooltipTop={tooltipTop}
                                    tooltipData={tooltipData}
                                    tooltipDateFormatter={tooltipDateFormatter}
                                />
                            )}
                            {/* ----- Tooltip end ----- */}
                        </>
                    );
                }}
            </ParentSize>
            {/* ----- Chart body end ----- */}

            {/* ----- Legend start ----- */}
            <HistogramLegend
                colorScale={colorScale}
                allSeries={localSeries}
                justifyLegend="start"
                activeSeries={activeSeriesDict}
                toggleActiveSeries={toggleActiveSeries}
            />
            {/* ----- Legend end ----- */}
        </Stack>
    );
};
