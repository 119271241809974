import { RowData } from "../types";
import { Prettify } from "mantine-react-table";

export enum SortingDirection {
    Asc = "asc",
    Desc = "desc",
}

export type SortingState<T extends RowData> = {
    columnId: Extract<keyof Omit<T, "rowExtraData">, string>;
    direction: SortingDirection;
};

export type UnControlledSortingProps = {};

export type ControlledSortingProps<T extends RowData> = {
    sorting?: SortingState<T> | null;
    onSortingChange?: (newSortingState: SortingState<T> | null) => void;
};

export type SortableColumns<
    Columns,
    ColumnArrayAsConst extends {
        accessorKey: keyof Columns;
        enableSorting?: boolean;
    },
> = Prettify<{
    [Column in keyof Columns as ColumnArrayAsConst extends {
        accessorKey: Column;
        enableSorting: true;
    }
        ? Column
        : never]: Columns[Column];
}>;
