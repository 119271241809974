import { ReactElement } from "react";
import { CSSObject, Group, SegmentedControl } from "@mantine/core";
import { TabOption } from "./types";

type TabProps<T extends string> = {
    dataId: string;
    selectedTab: T;
    onSelectedTabChange: (tab: T) => void;
    tabList: Array<TabOption<T>>;
    disabled?: boolean;
};

export const Tabs = <T extends string>({
    dataId,
    selectedTab,
    onSelectedTabChange,
    tabList,
    disabled = false,
}: TabProps<T>): ReactElement => (
    <Group p="left">
        <SegmentedControl
            id={dataId}
            data-id={dataId}
            transitionDuration={100}
            value={selectedTab}
            onChange={onSelectedTabChange}
            color="flexidaoGreen"
            w="fit-content"
            sx={(theme): CSSObject => ({
                background: "rgb(250, 251, 250)",
                boxShadow: theme.shadows.xs,
                ".mantine-SegmentedControl-control": {
                    border: "0px !important",
                },
            })}
            data={tabList}
            disabled={disabled}
        />
    </Group>
);
