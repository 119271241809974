import { formatNumber } from "./formatters/format-number";
import { ONE_BILLION, ONE_MILLION, ONE_THOUSAND, ONE_TRILLION } from "../consts";

type MagnitudeOptions = {
    minMagnitude?: Magnitude;
    maxMagnitude?: Magnitude;
};

export enum Magnitude {
    Tera = "T",
    Giga = "G",
    Mega = "M",
    Kilo = "k",
    None = "",
}

const MagnitudeValues: Record<Magnitude, number> = {
    [Magnitude.Tera]: ONE_TRILLION,
    [Magnitude.Giga]: ONE_BILLION,
    [Magnitude.Mega]: ONE_MILLION,
    [Magnitude.Kilo]: ONE_THOUSAND,
    [Magnitude.None]: 1,
};

/**
 * Get the magnitude given a minimum and maximum magnitude
 *
 * @param magnitude - The magnitude
 * @param maxMagnitude - The maximum magnitude
 * @param minMagnitude - The minimum magnitude
 *
 * @returns The magnitude between the minimum and maximum magnitudes
 */
export const getMagnitudeWithinMinAndMax = ({
    magnitude,
    maxMagnitude,
    minMagnitude,
}: {
    magnitude: Magnitude;
    maxMagnitude?: Magnitude;
    minMagnitude?: Magnitude;
}): Magnitude => {
    // If the minimum magnitude is greater than the maximum magnitude, throw an error
    if (
        minMagnitude &&
        maxMagnitude &&
        MagnitudeValues[minMagnitude] > MagnitudeValues[maxMagnitude]
    ) {
        throw new Error("The minimum magnitude cannot be greater than the maximum magnitude");
    }

    // If the magnitude is greater than the maximum magnitude, return the maximum magnitude
    if (maxMagnitude && MagnitudeValues[magnitude] > MagnitudeValues[maxMagnitude]) {
        return maxMagnitude;
    }

    // If the magnitude is less than the minimum magnitude, return the minimum magnitude
    if (minMagnitude && MagnitudeValues[magnitude] < MagnitudeValues[minMagnitude]) {
        return minMagnitude;
    }

    // Otherwise, return the magnitude
    return magnitude;
};

/**
 * Get the magnitude of a value, given the value and a maximum magnitude
 *
 * @param value - The value to get the magnitude of
 * @param options - The minimum and maximum magnitude
 *
 * @returns The magnitude of the value
 */
export const getMagnitudeFromValue = (value: number, options?: MagnitudeOptions): Magnitude => {
    // Get the absolute value of the number in order to consider negative numbers, too
    const absoluteValue = Math.abs(value);
    let magnitudeFromValue: Magnitude = Magnitude.None;

    if (absoluteValue >= MagnitudeValues[Magnitude.Tera]) {
        magnitudeFromValue = Magnitude.Tera;
    } else if (absoluteValue >= MagnitudeValues[Magnitude.Giga]) {
        magnitudeFromValue = Magnitude.Giga;
    } else if (absoluteValue >= MagnitudeValues[Magnitude.Mega]) {
        magnitudeFromValue = Magnitude.Mega;
    } else if (absoluteValue >= MagnitudeValues[Magnitude.Kilo]) {
        magnitudeFromValue = Magnitude.Kilo;
    }

    return getMagnitudeWithinMinAndMax({
        magnitude: magnitudeFromValue,
        maxMagnitude: options?.maxMagnitude,
        minMagnitude: options?.minMagnitude,
    });
};

/**
 * Convert a value to a magnitude
 *
 * @param value - The value to convert
 * @param magnitude - The magnitude to convert to
 *
 * @returns The value converted to the magnitude
 */
export const convertToMagnitude = (value: number, magnitude: Magnitude): number => {
    switch (magnitude) {
        case Magnitude.Tera:
            return value / MagnitudeValues[Magnitude.Tera];
        case Magnitude.Giga:
            return value / MagnitudeValues[Magnitude.Giga];
        case Magnitude.Mega:
            return value / MagnitudeValues[Magnitude.Mega];
        case Magnitude.Kilo:
            return value / MagnitudeValues[Magnitude.Kilo];
        default:
            return value / MagnitudeValues[Magnitude.None];
    }
};

/**
 * Round a number to a magnitude for electricity
 *
 * @param value - The value to round
 * @param options - The options for rounding (minimum and maximum magnitude)
 *
 * @returns The rounded value and its magnitude
 */
export const physicalRoundForElectricityWithoutFormatting = (
    value: number | null,
    options?: MagnitudeOptions,
): [number | null, Magnitude] => {
    if (value == null || isNaN(value)) {
        return [null, Magnitude.None];
    }

    const magnitude: Magnitude = getMagnitudeFromValue(value, options);
    const convertedValue: number = convertToMagnitude(value, magnitude);

    return [convertedValue, magnitude];
};

/**
 * Round a number to a magnitude for electricity
 *
 * @param value - The value to round
 * @param decimals - The number of decimals to round to
 * @param options - The options for rounding (minimum and maximum magnitude)
 *
 * @returns The rounded value as a formatted string and its magnitude
 */
export const physicalRoundForElectricity = (
    value: number | null,
    decimals: number = 0,
    options?: MagnitudeOptions,
): [string, Magnitude] => {
    const [convertedValue, magnitude] = physicalRoundForElectricityWithoutFormatting(
        value,
        options,
    );

    return [formatNumber(convertedValue, decimals), magnitude];
};
