import {
    BooleanColumnType,
    DateColumnType,
    DatetimeColumnType,
    DivisionColumnType,
    NumberColumnType,
    StringColumnType,
} from "@flexidao/ui-lib";

export const getStringValue = <T extends string | null>(value: StringColumnType<T>): T => {
    if (value == null) {
        return value;
    }

    if (typeof value === "string") {
        return value;
    }

    return value.value;
};

export const getNumberValue = <T extends number | null>(value: NumberColumnType<T>): T => {
    if (value == null) {
        return value;
    }

    return value.value;
};

export const getDateValue = <T extends Date | null>(value: DateColumnType<T>): T => {
    if (value == null) {
        return value;
    }

    return value.date;
};

export const getDatetimeValue = <T extends Date | null>(value: DatetimeColumnType<T>): T => {
    if (value == null) {
        return value;
    }

    return value.date;
};

export const getBooleanValue = <T extends boolean | null>(value: BooleanColumnType<T>): T => {
    if (value == null) {
        return value;
    }

    return value;
};

export const getDivisionValue = <T extends number | null, K extends number | null>(
    value: DivisionColumnType<T, K>,
): { value: T; total: K } => {
    return {
        value: value.value,
        total: value.total,
    };
};
