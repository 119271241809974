import { ReactNode } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginErrorCustomPage } from "../templates";

type AuthenticatedComponentProps = {
    content: ReactNode;
    fallback?: ReactNode;
    skeleton?: ReactNode;
};

/**
 * Contains logic for displaying `content`/`skeleton` components depending on authentication status.
 */
export const AuthenticatedComponent = ({
    content,
    skeleton,
}: AuthenticatedComponentProps): JSX.Element => {
    const { isAuthenticated, isLoading, loginWithRedirect, error } = useAuth0();
    if (isLoading) {
        return <>{skeleton}</>;
    }
    if (isAuthenticated) {
        return <>{content}</>;
    }

    if (error) {
        return <LoginErrorCustomPage message={error.message.toString()} />;
    }

    console.info("User is not authenticated. Redirecting to login page.");
    loginWithRedirect();
    return <></>;
};
