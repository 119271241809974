import { scaleLinear } from "@visx/scale";
import {
    ChartDimensions,
    ChartDisplayOptions,
    ChartMargin,
    defaultChartDisplayOptions,
} from "../utils";
import { HeatmapTemplate } from "./template";
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import { MantineTheme, useMantineTheme } from "@mantine/core";
import { ReactElement } from "react";
import { Bins, Bin } from "@visx/mock-data/lib/generators/genBins";
import { DAYS_IN_YEAR, HOURS_IN_DAY } from "../../../consts";

function max<Datum>(data: Datum[], value: (d: Datum) => number): number {
    return Math.max(...data.map(value));
}

// accessors
const bins: (d: Bins) => Array<Bin> = (d: Bins) => d.bins;

export type HeatmapGradient = {
    startLabel: string;
    endLabel: string;
    stops: {
        color: string;
        percent: number;
    }[];
};

export type HeatmapControllerProps = {
    data: Array<Bins>;
    axisColor: string;
    maxValue: number;
    gradient: HeatmapGradient;
    noDataColor: string;
    displayOptions: ChartDisplayOptions;
    dimensions: ChartDimensions;
};

const HeatmapController = ({
    dimensions,
    data,
    axisColor,
    maxValue,
    gradient,
    noDataColor,
    displayOptions = defaultChartDisplayOptions,
}: HeatmapControllerProps): ReactElement | null => {
    const { innerWidth, parentWidth, innerHeight } = dimensions;
    // bounds
    const bucketSizeMax = max(data, (d) => bins(d).length);
    const binWidth = innerWidth / data.length;
    const binHeight = innerHeight / bucketSizeMax;

    // scales
    const xScale = scaleLinear<number>({
        domain: [0, data.length > 0 ? data.length : DAYS_IN_YEAR],
    }).range([0, innerWidth]);

    const yScale = scaleLinear<number>({
        domain: [0, data.length > 0 ? bucketSizeMax : HOURS_IN_DAY],
    }).range([0, innerHeight]);

    const range = [noDataColor, ...gradient.stops.map((stop) => stop.color)];

    const domain = [
        -1,
        // eslint-disable-next-line no-magic-numbers
        ...gradient.stops.map((stop) => maxValue * (stop.percent / 100)),
    ];

    const colorScale = scaleLinear<string>({
        range,
        domain,
    });

    const minWidthToRender = 10;
    if (parentWidth < minWidthToRender) {
        return null;
    }

    return (
        <HeatmapTemplate
            dimensions={dimensions}
            xScale={xScale}
            yScale={yScale}
            gradient={gradient}
            colorScale={colorScale}
            noDataColor={noDataColor}
            data={data}
            bucketSizeMax={bucketSizeMax}
            binWidth={binWidth}
            binHeight={binHeight}
            axisColor={axisColor}
            displayOptions={displayOptions}
        />
    );
};

type HeatmapProps = {
    data: Array<Bins>;
    gradient: HeatmapGradient;
    maxValue: number;
    noDataColor: string;
    margin?: ChartMargin;
    displayOptions?: ChartDisplayOptions;
};

export const Heatmap = ({
    margin = { top: 50, right: 0, bottom: 50, left: 60 },
    displayOptions = defaultChartDisplayOptions,
    ...props
}: Omit<HeatmapProps, "width" | "height" | "axisColor">): ReactElement => {
    const theme: MantineTheme = useMantineTheme();
    const axisColor: string = theme.colors.flexidaoGrey![5];
    if (!displayOptions.showAxes.left) {
        margin.left = 0;
    }
    if (!displayOptions.showLegend) {
        margin.bottom = 0;
    }
    return (
        <ParentSize>
            {({ width: parentWidth, height: parentHeight }): JSX.Element => {
                const innerWidth = parentWidth - margin.left - margin.right;
                const innerHeight = parentHeight - margin.top - margin.bottom;
                return (
                    <HeatmapController
                        dimensions={{ parentWidth, parentHeight, margin, innerHeight, innerWidth }}
                        displayOptions={displayOptions}
                        axisColor={axisColor}
                        {...props}
                    />
                );
            }}
        </ParentSize>
    );
};
