import { MeterDataDto, SanityWarning } from "@flexidao/dto";
import * as D from "schemawax";
import { isoTimestampDecoder } from "../utils";
import {
    expectedIntervalReadingTypeDecoder,
    expectedReadingGranularityDecoder,
    providerDecoder,
    siteTypeDecoder,
} from "./misc";

export const basicMeterDecoder: D.Decoder<MeterDataDto.BasicMeter> = D.object({
    required: {
        meterId: D.number,
        meterProviderCode: D.string,
        tenantId: D.string,
        providerId: providerDecoder,
        enabled: D.boolean,
        initialTimeToCheckUtc: isoTimestampDecoder,
        siteId: D.nullable(D.string),
        endTimeToCheckUtc: D.nullable(isoTimestampDecoder),
        expectedIntervalReadingType: expectedIntervalReadingTypeDecoder,
        expectedReadingGranularity: expectedReadingGranularityDecoder,
        expectedDelayDays: D.nullable(D.number),
    },
});

export const basicMeterArrayDecoder: D.Decoder<Array<MeterDataDto.BasicMeter>> =
    D.array(basicMeterDecoder);

export const intervalMeterDecoder: D.Decoder<MeterDataDto.IntervalMeter> = D.object({
    required: {
        meterId: D.number,
        meterCode: D.string,
        siteName: D.nullable(D.string),
        regionName: D.nullable(D.string),
        dataProvider: D.string,
        expectedIntervalReadingType: expectedIntervalReadingTypeDecoder,
        expectedReadingGranularity: expectedReadingGranularityDecoder,
        expectedDelayDays: D.nullable(D.number),
        initialTimeToCheckUtc: isoTimestampDecoder,
        endTimeToCheckUtc: D.nullable(isoTimestampDecoder),
    },
});

export const getIntervalMetersResponseDecoder: D.Decoder<MeterDataDto.GetIntervalMetersResponse> =
    D.object({
        required: {
            meters: D.array(intervalMeterDecoder),
            totalCount: D.number,
        },
    });

export const getIntervalMetersFiltersResponseDecoder: D.Decoder<MeterDataDto.GetIntervalMetersFiltersResponse> =
    D.object({
        required: {
            sites: D.array(
                D.nullable(
                    D.object({
                        required: {
                            siteId: D.string,
                            siteName: D.string,
                        },
                    }),
                ),
            ),
            regions: D.array(
                D.nullable(
                    D.object({
                        required: {
                            regionId: D.string,
                            regionName: D.string,
                        },
                    }),
                ),
            ),
            meterTypes: D.array(
                D.object({
                    required: {
                        meterType: expectedIntervalReadingTypeDecoder,
                    },
                }),
            ),
        },
    });

export const billingMeterDecoder: D.Decoder<MeterDataDto.BillingMeter> = D.object({
    required: {
        meterId: D.number,
        meterCode: D.string,
        siteName: D.nullable(D.string),
        regionName: D.nullable(D.string),
        dataProvider: D.string,
        initialTimeToCheckUtc: isoTimestampDecoder,
        endTimeToCheckUtc: D.nullable(isoTimestampDecoder),
    },
});

export const getBillingMetersResponseDecoder: D.Decoder<MeterDataDto.GetBillingMetersResponse> =
    D.object({
        required: {
            meters: D.array(billingMeterDecoder),
            totalCount: D.number,
        },
    });

export const getBillingMetersFiltersResponseDecoder: D.Decoder<MeterDataDto.GetBillingMetersFiltersResponse> =
    D.object({
        required: {
            sites: D.array(
                D.nullable(
                    D.object({
                        required: {
                            siteId: D.string,
                            siteName: D.string,
                        },
                    }),
                ),
            ),
            regions: D.array(
                D.nullable(
                    D.object({
                        required: {
                            regionId: D.string,
                            regionName: D.string,
                        },
                    }),
                ),
            ),
        },
    });

const dataPerMeterRowDecoder: D.Decoder<MeterDataDto.MeterDataMeterBreakdownRow> = D.object({
    required: {
        meterCode: D.string,
        consumptionWh: D.nullable(D.number),
        productionWh: D.nullable(D.number),
        numReadings: D.number,
        expectedNumReadings: D.number,
        dataCompleteness: D.number,
        dataProvider: D.string,
        lastDataUpdate: D.nullable(isoTimestampDecoder),
        dateOfFirstReading: D.nullable(isoTimestampDecoder),
        dateOfLastReading: D.nullable(isoTimestampDecoder),
        currentDelayDays: D.nullable(D.number),
        expectedDelayDays: D.nullable(D.number),
        initialTimeToCheckUtc: isoTimestampDecoder,
        endTimeToCheckUtc: D.nullable(isoTimestampDecoder),
    },
});

const dataPerMeterDecoder = D.nullable(D.array(dataPerMeterRowDecoder));

export const meterDataSiteBreakdownRowDecoder: D.Decoder<MeterDataDto.MeterDataSiteBreakdownRow> =
    D.object({
        required: {
            siteName: D.string,
            siteType: siteTypeDecoder,
            energySource: D.nullable(D.string),
            region: D.string,
            biddingZone: D.string,
            timeZone: D.string,
            consumptionWh: D.nullable(D.number),
            productionWh: D.nullable(D.number),
            numReadings: D.number,
            expectedNumReadings: D.number,
            dataCompleteness: D.number,
            dataProvider: D.string,
            lastDataUpdate: D.nullable(isoTimestampDecoder),
            dateOfFirstReading: D.nullable(isoTimestampDecoder),
            dateOfLastReading: D.nullable(isoTimestampDecoder),
            currentDelayDays: D.nullable(D.number),
            expectedDelayDays: D.nullable(D.number),
        },
        optional: {
            dataPerMeter: dataPerMeterDecoder,
        },
    });

export const meterDataSiteBreakdownDecoder: D.Decoder<MeterDataDto.MeterDataSiteBreakdown> =
    D.object({
        required: {
            rows: D.array(meterDataSiteBreakdownRowDecoder),
            rowCount: D.number,
        },
    });

export const activeSitesKpiDecoder: D.Decoder<MeterDataDto.ActiveSitesKpi> = D.object({
    required: {
        totalActiveSites: D.number,
        activeConsumptionSites: D.number,
        activeProductionSites: D.number,
    },
});

export const totalConsumptionAndProductionKpiDecoder: D.Decoder<MeterDataDto.TotalConsumptionAndProductionKpi> =
    D.object({
        required: {
            totalConsumptionWh: D.nullable(D.number),
            totalProductionWh: D.nullable(D.number),
        },
    });

export const regionOptionDecoder: D.Decoder<MeterDataDto.RegionOption> = D.object({
    required: {
        regionId: D.string,
        regionName: D.string,
    },
});

export const regionOptionArrayDecoder: D.Decoder<Array<MeterDataDto.RegionOption>> =
    D.array(regionOptionDecoder);

export const biddingZoneOptionDecoder: D.Decoder<MeterDataDto.BiddingZoneOption> = D.object({
    required: {
        biddingZoneId: D.string,
        biddingZoneName: D.string,
    },
});

export const biddingZoneOptionArrayDecoder: D.Decoder<Array<MeterDataDto.BiddingZoneOption>> =
    D.array(biddingZoneOptionDecoder);

export const siteOptionDecoder: D.Decoder<MeterDataDto.SiteOption> = D.object({
    required: {
        siteId: D.string,
        siteName: D.string,
    },
});

export const siteOptionArrayDecoder: D.Decoder<Array<MeterDataDto.SiteOption>> =
    D.array(siteOptionDecoder);

export const providerOptionDecoder: D.Decoder<MeterDataDto.ProviderOption> = D.object({
    required: {
        providerId: D.string,
        providerName: D.string,
    },
});

export const providerOptionArrayDecoder: D.Decoder<Array<MeterDataDto.ProviderOption>> =
    D.array(providerOptionDecoder);

export const hourlyAggregatedMeterDataDecoder: D.Decoder<MeterDataDto.HourlyAggregatedMeterData> =
    D.object({
        required: {
            startTime: isoTimestampDecoder,
            consumptionWh: D.nullable(D.number),
            productionWh: D.nullable(D.number),
        },
    });

export const hourlyAggregatedMeterDataArrayDecoder: D.Decoder<
    Array<MeterDataDto.HourlyAggregatedMeterData>
> = D.array(hourlyAggregatedMeterDataDecoder);

export const dataCompletenessDecoder: D.Decoder<MeterDataDto.DataCompleteness> = D.object({
    required: {
        dataCompletenessRatio: D.number,
    },
});

export const sanityWarningDecoder: D.Decoder<SanityWarning> = D.literalUnion(
    SanityWarning.QuantityNotPositive,
    SanityWarning.QuantityNotNumber,
    SanityWarning.StartTimeNotValidDate,
    SanityWarning.EndTimeNotValidDate,
    SanityWarning.StartTimeGreaterThanEndTime,
    SanityWarning.StartTimeNotMultipleOf15,
    SanityWarning.EndTimeNotMultipleOf15,
    SanityWarning.OverlappingReadings,
    SanityWarning.QuantityEstimated,
    SanityWarning.BadFormattedReading,
    SanityWarning.MissingReading,
    SanityWarning.MissingQuantity,
    SanityWarning.ConnectionError,
    SanityWarning.BadTimestamps,
    SanityWarning.GranularityForIntervalNotSupported,
    SanityWarning.GranularityForBillingNotSupported,
);
export const hourlyAggregatedWarningsPerMeterDecoder: D.Decoder<MeterDataDto.HourlyAggregatedWarningsPerMeter> =
    D.object({
        required: {
            meterCode: D.string,
            siteName: D.string,
            region: D.string,
            biddingZone: D.string,
            provider: providerDecoder,
            date: isoTimestampDecoder,
            warnings: D.array(sanityWarningDecoder),
        },
    });

export const hourlyAggregatedWarningsPerMeterArrayDecoder: D.Decoder<
    Array<MeterDataDto.HourlyAggregatedWarningsPerMeter>
> = D.array(hourlyAggregatedWarningsPerMeterDecoder);

export const consumptionBySiteDecoder: D.Decoder<MeterDataDto.ConsumptionBySite> = D.object({
    required: {
        page: D.array(
            D.object({
                required: {
                    siteId: D.string,
                    siteName: D.string,
                    countryName: D.string,
                    consumptionWh: D.number,
                },
            }),
        ),
        totalSites: D.number,
    },
});
