import { ReactElement } from "react";
import { Text } from "@mantine/core";
import { formatDate } from "@flexidao/ui-lib";
import { EmptyCell } from "./empty-cell";
import { getClassNameForSubRow } from "./utils";

type DateCellProps = {
    date: Date | null;
    timezone?: string | null;
    isSubRow?: boolean;
};

export const DateCell = ({ date, timezone, isSubRow = false }: DateCellProps): ReactElement => {
    if (date == null) {
        return <EmptyCell isSubRow={isSubRow} />;
    }

    const formattedDate: string = formatDate(date, timezone ?? undefined);

    return (
        <Text size={14} className={getClassNameForSubRow(isSubRow)}>
            {formattedDate}
        </Text>
    );
};
