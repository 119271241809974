import {
    EacCodeUseTypeEnum,
    GlobalRegion,
    reportHubComponents,
    TrackingInstrumentType,
    ZoneDataAccessLevel,
} from "./";
import { SourcingMethodEnum } from "./enum";
import { components } from "./schemas/report-hub-api-schema";
import { TypesAreEqual } from "./validate-strict-decoder";

export type IKind<T> = {
    kind: T;
};

export enum ReportHubFeatureFlag {}

export namespace ReportHubDto {
    export type ContractOption = {
        contractId: string;
        name: string;
    };

    export type GetContractsReceivingEacsResponse = Array<ContractOption>;

    export type ContractTrackingKpis = {
        interval_Wh: number;
        intervalPpa_Wh: number;
        intervalGreenTariff_Wh: number;
        billing_Wh: number;
        billingPpa_Wh: number;
        billingGreenTariff_Wh: number;
        eacsReceived_Wh: number;
        eacsAllocated_Wh: number;
        eacsPendingAllocation_Wh: number;
    };

    export type ContractTrackingKpisPpa = reportHubComponents["schemas"]["ContractTrackingKpisPpa"];

    export type ContractTrackingOverviewPpaRow =
        reportHubComponents["schemas"]["ContractTrackingOverviewPpaRow"];

    export type ContractTrackingOverviewPpa =
        reportHubComponents["schemas"]["ContractTrackingOverviewPpa"];

    export type ContractTrackingKpisGreenTariff =
        reportHubComponents["schemas"]["ContractTrackingKpisGreenTariff"];
    export type ContractTrackingOverviewGreenTariffRow =
        reportHubComponents["schemas"]["ContractTrackingOverviewGreenTariffRow"];

    export type ContractTrackingOverviewGreenTariff =
        reportHubComponents["schemas"]["ContractTrackingOverviewGreenTariff"];

    export type ContractTrackingKpisUnbundled =
        reportHubComponents["schemas"]["ContractTrackingKpisUnbundled"];

    export type ContractTrackingOverviewUnbundledRow =
        reportHubComponents["schemas"]["ContractTrackingOverviewUnbundledRow"];

    export type ContractTrackingOverviewUnbundled =
        reportHubComponents["schemas"]["ContractTrackingOverviewUnbundled"];

    export type ContractTrackingMonthlyPpaRow =
        reportHubComponents["schemas"]["ContractTrackingMonthlyPpaRow"];

    export type ContractTrackingMonthlyGreenTariffRow =
        reportHubComponents["schemas"]["ContractTrackingMonthlyGreenTariffRow"];

    export type Region = {
        regionId: string;
        name: string;
    };
    export type GetRegions = Array<Region>;

    export type Site = {
        siteId: string;
        name: string;
    };
    export type GetContractsPpa = Array<ContractOptionPpa>;
    export type GetContractsGreenTariff = Array<ContractOption>;

    export type ContractOptionPpa = {
        productionSitesIds: Array<string>;
        contractId: string;
        name: string;
    };
    export type GetSites = Array<Site>;

    export type ContractTrackingMonthlyPpa = Array<ContractTrackingMonthlyPpaRow>;
    export type ContractTrackingMonthlyGreenTariff = Array<ContractTrackingMonthlyGreenTariffRow>;

    export type Allocation = reportHubComponents["schemas"]["AllocationV2"];

    export enum GetAllocationsOrderBy {
        UploadDate = "uploadDate",
        ConsumptionOrganizationName = "consumptionOrganizationName",
        TransactionFromName = "transactionFromName",
        AllocatedTo = "allocatedTo",
    }

    export type AllocationAttestation = {
        transactionId: string;
        uploadDate: Date;
        allocatedTo: string | null;
        transactionFromName: string;
        consumptionOrganizationName?: string;
        consumptionPeriodStart: Date;
        consumptionPeriodEnd: Date;
        trackingInstrumentType: TrackingInstrumentType.Attestation;
        scheme: string | null;
        productionSiteName?: string;
    };

    export type AllocationCancellation = {
        transactionId: string;
        uploadDate: Date;
        allocatedTo: string | null;
        transactionFromName: string;
        consumptionOrganizationName?: string;
        consumptionPeriodStart: Date;
        consumptionPeriodEnd: Date;
        trackingInstrumentType: TrackingInstrumentType.EAC;
        productionSiteId: string;
        productionSiteName: string;
        consumptionSiteId: string | null;
        productionPeriodStart: Date;
        productionPeriodEnd: Date;
    };

    export type GetAllocationsByTenantIdResponse = {
        allocations: Array<Allocation>;
        totalAllocations: number;
    };

    // EX-4909 only using receivesEacs for now
    export type BaseContract = {
        tenantId: string;
        contractId: string;
        // cancellationParty: {
        //     eacCodeId: number;
        //     eacCode: string;
        // } | null;
        // beneficiaries: Array<{
        //     eacCodeId: number;
        //     eacCode: string;
        // }>;
        receivesEacs: boolean;
    };

    export type UpdateContractPayload = {
        receivesEacs: boolean;
        cancellationParty: number | null;
        beneficiaries: Array<number>;
    };

    export type SourcingMethod = reportHubComponents["schemas"]["SourcingMethod"];

    // I have to export this because else TypeScript will complain about the unused variable
    export const __sourcingMethodsAreEqual: TypesAreEqual<
        reportHubComponents["schemas"]["SourcingMethod"],
        `${SourcingMethodEnum}`
    > = SourcingMethodEnum.Unknown;

    export type ContractReportingSettings =
        reportHubComponents["schemas"]["ContractReportingSettings"];

    export type UpdateContractReportingSettingsPayload =
        reportHubComponents["schemas"]["UpdateContractReportingSettingsPayload"];

    export type EacCode = {
        tenantId: string;
        eacCodeId: number;
        eacCode: string;
        registryId: string;
        useType: EacCodeUseTypeEnum;
    };

    export type Country = {
        countryId: string;
        name: string;
        enabled: boolean;
        marketId: string | null;
        passiveProcurement: boolean;
    };

    export type BiddingZone = {
        zoneId: string;
        countryId: string;
        name: string;
        timezones: Array<string>;
        enabled: boolean;
        dataAccess: ZoneDataAccessLevel;
        marketId: string | null;
        regionId: string;
    };

    export type Market = {
        marketId: string;
        name: string;
        description: string;
    };

    export type AnnualEmissionFactor = {
        biddingZoneId: string;
        reportingYear: number;
        marketBasedEmissions_g_Wh: number | null;
        marketBasedEmissionsSourceId: string | null;
        marketBasedEmissionsSourceName: string | null;
        marketBasedEmissionsSourceYear: number | null;
        locationBasedEmissions_g_Wh: number | null;
        locationBasedEmissionsSourceId: string | null;
        locationBasedEmissionsSourceName: string | null;
        locationBasedEmissionsSourceYear: number | null;
    };

    export type AssignmentDetail = components["schemas"]["AssignmentDetail"];

    export enum CountryAssignmentsSortField {
        SourcingMethod = "sourcingMethod",
        AssignedWh = "assignedWh",
        EnergySourceId = "energySourceId",
        EacSchemeId = "eacSchemeId",
        ProductionCountryName = "productionCountryName",
        ProductionPeriodStart = "productionPeriodStart",
        ProductionSiteCommissioningDate = "productionSiteCommissioningDate",
        ContractStartTime = "contractStartTime",
    }

    export type CountryAssignmentsResponse = {
        assignments: Array<AssignmentDetail>;
        totalCount: number;
    };

    export type CountryAssignmentKpis = {
        countryId: string;
        countryName: string;
        consumptionWh: number;
        assignedWh: number;
        locationBasedEmissionsT: number;
        marketBasedEmissionsT: number;
        passiveProcurement: boolean;
        globalRegionId: GlobalRegion;
        biddingZoneIds: Array<string>;
    };

    export type GetReportingKpisResponse = {
        totals: {
            consumptionWh: number;
            assignedWh: number;
            locationBasedEmissionsT: number;
            marketBasedEmissionsT: number;
        };
        byCountry: Array<CountryAssignmentKpis>;
    };

    export type Attestation = {
        attestationId?: string;
        attestationDocumentId?: number;
        consumptionOrganizationName?: string | null;
        consumptionPeriodEnd?: string;
        consumptionPeriodStart?: string;
        consumptionTimezone?: string;
        eacSchemeId?: string | null;
        percentageCoverage?: number;
        productionSiteCommissioningDate?: string | null;
        productionSiteName?: string | null;
        tenantId?: string;
        transactionFrom?: string;
        transactionId?: string;
        quantityWh?: number;
    };
    export type AllocationFilters = {
        transactionIds: Array<string>;
        productionSiteNames: Array<string | null>;
        allocatedToNames: Array<string | null>;
    };

    export type GlobalOverviewKpis = {
        consumption: {
            totalWh: number;
            trackingInstrumentCoverage: number;
        };
        volumes: {
            totalWh: number;
            ppaWh: number;
            greenTariffWh: number;
            unbundledWh: number;
        };
        trackingInstruments: {
            totalWh: number;
            eacCancelledWh: number;
            eacTotalWh: number;
            attestationTotalWh: number;
        };
    };

    export type GlobalOverviewCountryCoverageDetail = {
        tenantId: string;
        countryId: string;
        countryName: string;
        consumptionWh: number;
        purchasedVolumeWh: number;
        trackingInstrumentWh: number;
        coveredShare: number;
        uncoveredWh: number;
    };
    export type GlobalOverviewCountriesCoverage = {
        countriesCoverage: Array<GlobalOverviewCountryCoverageDetail>;
    };
    export type GetCountries = Array<Country>;

    export type ReportingPeriod = {
        tenantId: string;
        year: number;
        startReportingPeriod: Date;
        endReportingPeriod: Date;
    };
    export type GetReportingPeriods = Array<ReportingPeriod>;
}
